import isNull from 'lodash/isNull';
import { useEffect, useState } from 'react';

const IPWHOIS_URL = 'https://ipwhois.pro/?key=MjdM2iBKJn5AXnCH';
type Ipwhois = {
  country_code: string;
};

let ipwhois: Ipwhois | null = null;
let fetching: Promise<Ipwhois> | null = null;

/**
 * This is a quick dirty fetch and cache use of ipwhois
 * ripped key from levelshealth.com original homepage in wordpress.
 */
const useIpwhois = () => {
  const [val, setVal] = useState(ipwhois);

  useEffect(() => {
    // If `ipwhois` has not been loaded yet AND if we are not currently loading it
    if (isNull(ipwhois) && isNull(fetching)) {
      // Set loading to promise
      fetching = fetch(IPWHOIS_URL)
        .then(response => response.json())
        .then(data => {
          // set this hook state and cache the response for `ipwhois`
          setVal((ipwhois = data));
          // return so that other hook calls before response can subscribe with `then`
          return data;
        })
        .catch(console.error);
    }

    // if another hook is called and another hook is already fetching
    if (!isNull(fetching)) {
      // subscribe to the resolve and set value to the response
      fetching.then(setVal).catch(console.error);
    }
  }, [val]);

  // return set value when any updates to the value happen.
  return val;
};

export default useIpwhois;
