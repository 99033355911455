import cx from 'classnames';
import { FC } from 'react';

export type TitleOrder = 1 | 2 | 3 | 4 | 5 | 6;
export type TitleSize = `h${TitleOrder}`;

export type TitleProps = {
  size: TitleSize;
  className?: string;
  style?: Object;
};

const TitleClassNames = {
  h1: 'text-4xl md:text-5xl md:leading-[3.5rem] lg:text-6xl lg:leading-[4rem] lg:mb-0',
  h2: 'text-3xl md:text-4xl lg:text-5xl lg:leading-[3.5rem]',
  h3: 'text-2xl md:text-3xl lg:text-4xl',
};

const Title: FC<TitleProps> = ({ size, children, className, style }) => {
  const Heading = size;
  return (
    <Heading style={style} className={cx(`dark:text-white`, TitleClassNames[size], className)}>
      {children}
    </Heading>
  );
};

export default Title;
