/**
 * Our naming convention for posthog events is based on https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
 *
 * To summarize each event should be composed of an object eg: User, Cart, Order, etc. and a action eg: Created, Updated, Deleted, etc.
 *
 * Rules:
 *
 * 1. Use past tense for action
 * 2. Use UPPERCASE_SNAKE_CASE for the enum key
 * 3. Use Proper Case for the enum string value
 * 4. Organize the enum keys in alphabetical order
 */

import posthog from 'posthog-js';
import { ValueOf } from 'ts-pattern/dist/types/helpers';
import isBrowser from 'util/isBrowser';

export const POSTHOG_EVENT_NAME = Object.freeze({
  HOMEPAGE_SIGNUP_CTA_CLICK: 'Homepage Signup CTA Click',
  SIGNUP_STARTED: 'Signup Started',
  SOFTWARE_CHOICE: 'Software Choice',
  HARDWARE_CHOICE: 'Hardware Choice',
  LABS_CHOICE: 'Labs Choice',
  PAYMENT_SHIPPING: 'Payment and Shipping',
  REVIEW_ORDER: 'Review Order',
  EMAIL_CAPTURED: 'Email Captured',
  COMPLETED_PURCHASE: 'Completed Purchase',
  //
  QUIZ_INTRO: 'Quiz Intro',
  QUIZ_QUESTION_GOALS: 'Quiz Question - Goals',
  QUIZ_QUESTION_HAS_USED_CGM: 'Quiz Question - Has Used a CGM',
  QUIZ_QUESTION_BUNDLE: 'Quiz Question - Bundle',
  QUIZ_QUESTION_HARDWARE_NEEDED: 'Quiz Question - Hardware Needed',
  QUIZ_QUESTION_HARDWARE_BRAND: 'Quiz Question - Hardware Brand',
  QUIZ_QUESTION_JOIN_STUDY: 'Quiz Question - Join Study',
  QUIZ_QUESTION_LABS_PLAN: 'Quiz Question - Labs Plan',
  QUIZ_EMAIL: 'Quiz Email',
  QUIZ_EMAIL_SKIPPED: 'Quiz Email Skipped',
  QUIZ_SKIP: 'Quiz Skipped',
  QUIZ_SUMMARY: 'Quiz Summary',
});

// NOTE posthogCapture returns a function so you need to call it as
// posthogCapture(POSTHOG_EVENT_NAME)() instead of posthogCapture(POSTHOG_EVENT_NAME)
export function posthogCapture(event: ValueOf<typeof POSTHOG_EVENT_NAME>, properties?: Record<string, any>) {
  let shouldForceV4 = false;
  if (isBrowser) shouldForceV4 = window.location.pathname.includes('join');
  return () => posthog.capture(event, { ...properties, ...(shouldForceV4 ? { '$feature/signup-v4': 'v4' } : {}) });
}
