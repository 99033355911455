import Title from 'components/texts/Title';
import Image from 'next/image';

import GetStartedButton from 'components/buttons/GetStartedButton';

import Balancer from 'react-wrap-balancer';

const Labs = () => {
  return (
    <>
      <style jsx>{`
        .section {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          background: linear-gradient(to top, #23272b, #13171b);
          padding-bottom: 50px;
          border-bottom: 2px solid white;
          padding-top: 50px;
        }
        .image-column {
          display: flex;
          flex-direction: column;
          width: 50%;
          flex-shrink: 0;
          position: relative;
          height: 900px;
        }
        .copy-column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 50%;
        }
        .link {
          text-decoration: underline;
          color: rgb(173, 236, 225);
        }
        .body {
          color: white;
          line-height: 2;
          font-size: 16px;
        }
        .body-container {
          width: 80%;
        }
        .h1-title {
          width: 90%;
        }
        @media (max-width: 900px) {
          .image-column {
            width: 90%;
          }
          .copy-column {
            width: 100%;
            align-items: center;
            padding-top: 40px;
          }
          .body-container {
            width: 90%;
          }
          .h1-title {
            text-align: center;
          }
        }
      `}</style>
      <div className="section">
        <div className="image-column">
          <Image src="/assets/home-page/labs/labs-image.webp" layout="fill" objectFit="scale-down" />
        </div>
        <div className="copy-column">
          <h1
            // NOTE I couldn't figure out how to get the Title component to work here so I just worked around it
            // We're deleting all of this at some point anyway so it doesn't matter
            className="dark:text-white text-4xl md:text-5xl md:leading-[3.5rem] lg:text-6xl lg:leading-[4rem] lg:mb-0 h1-title"
            style={{ color: 'white', marginBottom: 30 }}
          >
            <Balancer>Discover more with Levels Labs</Balancer>
          </h1>
          <div className="body-container">
            <Title size="h3" style={{ color: 'white', marginTop: 30, marginBottom: 10 }}>
              Simple Collection Process
            </Title>
            <p className="body">
              Complete bloodwork at a nearby Labcorp location; visits typically take under 20 minutes. Locate your nearest lab{' '}
              <a target="_blank" rel="noopener noreferrer" className="link" href="https://www.labcorp.com/precheck">
                here.
              </a>
            </p>
            <Title size="h3" style={{ color: 'white', marginTop: 30, marginBottom: 10 }}>
              Measure What Matters
            </Title>
            <p className="body">
              Measure key markers to gain insight into cardiovascular health (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="link"
                href="https://www.levelshealth.com/blog/why-apob-may-be-a-better-cholesterol-marker/?utm_source=social&utm_medium=linkinbio"
              >
                ApoB
              </a>
              ,{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.levelshealth.com/blog/why-are-triglycerides-so-important-to-metabolic-health/?utm_source=social&utm_medium=linkinbio"
                className="link"
              >
                Triglycerides
              </a>
              ), insulin resistance (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.levelshealth.com/blog/what-are-normal-insulin-levels-and-why-dont-we-test-it-more/?utm_source=social&utm_medium=linkinbio"
                className="link"
              >
                Fasting Insulin
              </a>
              ), metabolic health (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.levelshealth.com/blog/what-is-uric-acid-and-why-should-we-measure-it/?utm_source=social&utm_medium=linkinbio"
                className="link"
              >
                Uric Acid
              </a>
              ), and glucose levels (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.levelshealth.com/blog/hba1c-testing/?utm_source=social&utm_medium=linkinbio"
                className="link"
              >
                HbA1c
              </a>
              ).
            </p>
            <Title size="h3" style={{ color: 'white', marginTop: 30, marginBottom: 10 }}>
              Insights in the Levels App
            </Title>
            <p className="body">
              Results appear in the Levels app with personalized, actionable guidance on what the results mean and how to improve them.
              Schedule additional tests anytime.
            </p>
          </div>
          <GetStartedButton
            style={{
              marginTop: '2rem',
            }}
          >
            Sign Up to Get Started
          </GetStartedButton>
        </div>
      </div>
    </>
  );
};

export default Labs;
