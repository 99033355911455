import { useRouter } from 'next/router';

import { FC, useEffect, useState } from 'react';

import { PosthogFeatureFlagName } from '@lib/posthog/types';

import useIpwhois from '../../../util/useIpwhois';

import { isNull } from 'lodash';
import posthog from 'posthog-js';
import { POSTHOG_EVENT_NAME } from 'util/frontendPosthog';
import { isDefined } from 'util/parseUtils';
import PrimaryButtonV2, { ButtonProps } from '../../../components/buttons/PrimaryButtonV2';

const { NEXT_PUBLIC_APP_URL } = process.env;
const { HOMEPAGE_SIGNUP_QUIZ } = PosthogFeatureFlagName.enum;

/**
 * @summary Redirects user to /quiz or /us/signup depending on Posthog Feature Flag.
 * @see {@link https://us.posthog.com/experiments/10219}
 * @returns {string}
 */

export const getStartedUrl = (): string => {
  const isQuizEnabled = posthog.getFeatureFlag(HOMEPAGE_SIGNUP_QUIZ) === HOMEPAGE_SIGNUP_QUIZ;
  if (isQuizEnabled) return `${NEXT_PUBLIC_APP_URL}/quiz`;
  return `${NEXT_PUBLIC_APP_URL}/us/signup`;
};

const NON_US_CTA = {
  url: 'https://levels.link/waitlist-international?utm_campaign=homepage',
  text: 'Join Waitlist',
};

export const US_CTA = {
  url: getStartedUrl(),
  text: 'Get started',
};

/**
 * Uses a quick hacked ipwhois hook to match the production implementation
 * This looks up the users ip from the browser and returns the country code.
 * I think we should handle this more optimally in the future
 * but keep this implementation as not to introduce new complexities
 */

export const useGetStartedButton = () => {
  const ipwhois = useIpwhois();

  if (isNull(ipwhois)) return US_CTA;

  switch (ipwhois.country_code) {
    case 'US':
      return US_CTA;
    default:
      return NON_US_CTA;
  }
};

// TODO add JSDoc on why we need this
function mergeQueryParams(url: string, query: Object) {
  const r = new URL(url);
  for (const [k, v] of Object.entries(query)) {
    r.searchParams.set(k, v);
  }
  return r.href;
}

/**
 * @summary Button that navigates explicitly to the app url signup flow. This Home page is hosted at levelshealth.com, but the app is at app.levelshealth.com.
 * @param props
 * @returns {JSX.Element}
 */

export const GetStartedButton: FC<Partial<ButtonProps>> = (props): JSX.Element => {
  const { children } = props;
  const { url, text } = useGetStartedButton();
  const router = useRouter();
  const [href, setHref] = useState<string | undefined>(undefined);

  useEffect(() => {
    const value = mergeQueryParams(url, router.query);
    setHref(value);
  }, [href, router.query, url]);

  return (
    <PrimaryButtonV2
      href={href}
      variant={'secondary'}
      dataTestId="signup-join-button"
      {...props}
      onClick={() => url === US_CTA.url && posthog.capture(POSTHOG_EVENT_NAME.HOMEPAGE_SIGNUP_CTA_CLICK)}
    >
      {isDefined(children) ? children : text}
    </PrimaryButtonV2>
  );
};

export default GetStartedButton;
