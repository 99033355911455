import cx from 'classnames';
import Loader from 'components/loaders';
import React from 'react';

type ButtonType = 'button' | 'reset' | 'submit';

export interface ButtonProps {
  block?: boolean;
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  href?: string;
  loading?: boolean;
  size?: 'small' | 'normal';
  style?: React.CSSProperties;
  type?: ButtonType;
  variant?: 'primary' | 'secondary' | 'tertiary';
  onClick?: (event: React.MouseEvent) => void;
}

// Renders an anchor tag if an href is present, otherwise renders a button tag
// We want to render the semantically appropriate tag for accessibility reasons
// The component should look the same regardless of which tag is rendered
// We will eventually introduce a Touchable to handle this

/**
 *
 * @param block boolean - make button full width
 * @param children React.ReactNode - button contents
 * @param className string - additional classes
 * @param dataTestId string - data test id
 * @param disabled boolean - disable button
 * @param href string - link to
 * @param loading boolean - show loading spinner
 * @param onClick (event: React.MouseEvent) => void - click handler
 * @param size
 * @param type ButtonType - button type
 * @param variant 'primary' | 'secondary' - button variant
 * @constructor
 */

const Button: React.FC<ButtonProps> = ({
  loading,
  block = false,
  className,
  children,
  disabled,
  href,
  type,
  onClick,
  variant = 'primary',
  size = 'normal',
  dataTestId,
  style,
}) => {
  const sharedProps = {
    onClick,
    className: cx(
      'content-center',
      'items-center',
      'text-base',
      'font-semibold', // font-weight 600
      'leading-none',
      'rounded-full',
      'text-center',
      'px-8',
      'disabled:bg-disabled',
      'disabled:dark:bg-disabled-dark',
      'disabled:text-disabled-text',
      'disabled:dark:text-disabled-text-dark',
      {
        'flex': block,
        'inline-flex': !block,
        'w-full': block,
        'cursor-pointer': !disabled,
        'cursor-disabled': disabled,
      },
      variant === 'primary' && ['bg-btn-green-bg', 'dark:bg-accent-dark', 'text-accent-dark', 'dark:text-black'],
      variant === 'secondary' && ['bg-accent-dark text-black-title'],
      variant === 'tertiary' && ['bg-white text-black-title'],
      size === 'small' ? 'h-12' : 'h-14',
      className
    ),
    style,
  };

  const contents = (
    <div className="w-full flex items-center self-center justify-center whitespace-nowrap flex-nowrap">
      {loading && <Loader />}
      {children}
    </div>
  );

  // render "a" element if href is defined and type is not submit
  if (href != null && type !== 'submit') {
    return (
      <a href={href} {...sharedProps}>
        {contents}
      </a>
    );
  }

  // by default render "button" element
  return (
    <button data-testid={dataTestId} disabled={disabled} type={type ?? 'button'} {...sharedProps}>
      {contents}
    </button>
  );
};

export default Button;
